.image-preview{
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}
.image-preview-item{
    width: auto;
    height: 100px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .remove-image-button{
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        border: none;
        padding: 4px 8px;
        border-radius: 4px;
        cursor: pointer;
    }
}

